<template>
    <div>
        <!--탑메뉴-->
        <topbar-comp></topbar-comp>
        <left-bar-comp>
            <sports-left-bar-comp :display-position="sportsConst.GAME_DISPLAYPOSITION_1x2"></sports-left-bar-comp>
        </left-bar-comp>

        <div class="content_section">
            <div class="main">
                <div class="main_img">

                    <div class="m2">
                       <event-buttons-comp></event-buttons-comp>
                    </div>

                    <img src="../../assets/images/main/wine/main01.png" alt="" style="width: 100%">
                    <div class="main-buttons">
                        <router-link tag="button" to="/recharge">충전신청</router-link>
                        <router-link tag="button" to="/exchange">환전신청</router-link>
                        <router-link tag="button" to="/notice">공지사항</router-link>
                        <router-link tag="button" to="/eventlist">이벤트</router-link>
                        <router-link tag="button" to="/customer_center">고객센터</router-link>
                        <router-link tag="button" to="/sports_betinfo">베팅내역</router-link>
                        <router-link tag="button" to="/sports_result">경기결과</router-link>
                        <router-link tag="button" to="/culcheck">출석부</router-link>
                        <router-link tag="button" to="/message">쪽지함</router-link>
                        <router-link tag="button" to="/coupon">쿠폰함</router-link>
                    </div>
                </div>
                <div class="sub_img">
                    <div class="sub">
                        <router-link to="/sports" tag="span" style="cursor: pointer">
                            <img src="../../assets/images/main/wine/bn01.png" alt="">
                        </router-link>
                    </div>
                    <div class="sub">
                        <router-link to="/sports_inplay" tag="span" style="cursor: pointer">
                            <img src="../../assets/images/main/wine/bn02.png" alt="">
                        </router-link>
                    </div>
                    <div class="sub">
                        <router-link :to="{path: '/casinohonor', query: {type:1,t: new Date().getTime()}}" tag="span">

                            <img src="../../assets/images/main/wine/bn03.png" alt="">
                        </router-link>
                    </div>
                    <div class="sub">
                        <router-link :to="{path: '/leisure/bet365_superleague', query: {type:1,t: new Date().getTime()}}" tag="span">
                            <img src="../../assets/images/main/wine/bn04.png" alt="">
                        </router-link>
                    </div>

                    <div class="sub pc">
                        <router-link to="/eventlist" tag="span">
                            <img src="../../assets/images/main/wine/bn05.png" alt="">
                        </router-link>
                    </div>
                </div>


                <div class="m2">

                    <div class="main-minigame-mobile">
                        <div class="mg">
                            <router-link :to="{path:'/sports', query: {t: new Date().getTime()}}" tag="div">
                                <img src="../../assets/images/mobile/wine/menu/cross.jpg" alt="">
                            </router-link>
                        </div>
                        <div class="mg">
                            <router-link :to="{path:'/sports_ho', query: {t: new Date().getTime()}}" tag="div">
                                <img src="../../assets/images/mobile/wine/menu/handi.jpg" alt="">
                            </router-link>
                        </div>
                        <div class="mg">
                            <router-link :to="{path:'/sports_special', query: {t: new Date().getTime()}}" tag="div">
                                <img src="../../assets/images/mobile/wine/menu/special.jpg" alt="">
                            </router-link>
                        </div>
                        <div class="mg">
                            <router-link :to="{path:'/sports_inplay', query: {t: new Date().getTime()}}" tag="div">
                                <img src="../../assets/images/mobile/wine/menu/inplay.jpg" alt="">
                            </router-link>
                        </div>
                        <div class="mg">
                            <router-link :to="{path:'/casinohonor', query: {type:1, t: new Date().getTime()}}" tag="div">
                                <img src="../../assets/images/mobile/wine/menu/livecasino.jpg">
                            </router-link>
                        </div>
                        <div class="mg">
                            <router-link :to="{path:'/casinohonor', query: {type:2, t: new Date().getTime()}}" tag="div">
                                <img src="../../assets/images/mobile/wine/menu/slot.jpg">
                            </router-link>
                        </div>
                      <div class="mg">
                        <router-link :to="{path:'/casinohonor', query: {type:1, t: new Date().getTime()}}" tag="div">
                          <img src="../../assets/images/mobile/wine/menu/evopow.jpg">
                        </router-link>
                      </div>
                        <div class="mg">
                            <router-link tag="div"
                                         :to="{path:'/leisure/candy_dragontiger', query: {t: new Date().getTime()}}">
                                <img src="../../assets/images/mobile/wine/menu/dragontiger.jpg" alt="">
                            </router-link>
                        </div>

<!--                        <div class="mg">-->
<!--                            <router-link tag="div"-->
<!--                                         :to="{path:'/leisure/eospowerball1m', query: {t: new Date().getTime()}}">-->
<!--                                <img src="../../assets/images/mobile/wine/menu/eospowerball1m.jpg" alt="">-->
<!--                            </router-link>-->
<!--                        </div>-->
<!--                        <div class="mg">-->
<!--                            <router-link tag="div"-->
<!--                                         :to="{path:'/leisure/eospowerball3m', query: {t: new Date().getTime()}}">-->
<!--                                <img src="../../assets/images/mobile/wine/menu/eospowerball3m.jpg" alt="">-->
<!--                            </router-link>-->
<!--                        </div>-->
<!--                        <div class="mg">-->
<!--                            <router-link tag="div"-->
<!--                                         :to="{path:'/leisure/eospowerball5m', query: {t: new Date().getTime()}}">-->
<!--                                <img src="../../assets/images/mobile/wine/menu/eospowerball5m.jpg" alt="">-->
<!--                            </router-link>-->
<!--                        </div>-->

                        <div class="mg">
                            <router-link tag="div"
                                         :to="{path:'/leisure/sky_oddeven', query: {t: new Date().getTime()}}">
                                <img src="../../assets/images/mobile/wine/menu/skyodd.jpg" alt="">
                            </router-link>
                        </div>
                        <div class="mg">
                            <router-link tag="div"
                                         :to="{path:'/leisure/sky_speed_bakara', query: {t: new Date().getTime()}}">
                                <img src="../../assets/images/mobile/wine/menu/skybaccrat.jpg" alt="">
                            </router-link>
                        </div>
                        <div class="mg">
                            <router-link tag="div"
                                         :to="{path:'/leisure/sky_dice', query: {t: new Date().getTime()}}">
                                <img src="../../assets/images/mobile/wine/menu/skydice.jpg" alt="">
                            </router-link>
                        </div>

                        <div class="mg">
                            <router-link tag="div" :to="{path:'/leisure/ssd1', query: {t: new Date().getTime()}}">
                                <img src="../../assets/images/mobile/wine/menu/ssd1.jpg" alt="">
                            </router-link>
                        </div>
                        <div class="mg">
                            <router-link tag="div" :to="{path:'/leisure/ssd2', query: {t: new Date().getTime()}}">
                                <img src="../../assets/images/mobile/wine/menu/ssd2.jpg" alt="">
                            </router-link>
                        </div>
                        <div class="mg">
                            <router-link tag="div" :to="{path:'/leisure/ssd3', query: {t: new Date().getTime()}}">
                                <img src="../../assets/images/mobile/wine/menu/ssd3.jpg" alt="">
                            </router-link>
                        </div>

                        <div class="mg">
                            <router-link :to="{path:'/leisure/bet365_superleague', query: {t: new Date().getTime()}}"
                                         tag="div">
                                <img src="../../assets/images/mobile/wine/menu/bet365soccer.jpg" alt="">
                            </router-link>
                        </div>
                        <div class="mg">
                            <router-link tag="div"
                                         :to="{path:'/leisure/bet365_water', query: {t: new Date().getTime()}}">
                                <img src="../../assets/images/mobile/wine/menu/bet365bascketball.jpg" alt="">
                            </router-link>
                        </div>
                        <div class="mg">
                            <router-link tag="div"
                                         :to="{path:'/leisure/bet365_goldenhill', query: {t: new Date().getTime()}}">
                                <img src="../../assets/images/mobile/wine/menu/bet365dog.jpg" alt="">
                            </router-link>
                        </div>
                        <div class="mg">
                            <router-link tag="div"
                                         :to="{path:'/leisure/bet365_horserace2', query: {t: new Date().getTime()}}">
                                <img src="../../assets/images/mobile/wine/menu/bet365horse.jpg" alt="">
                            </router-link>
                        </div>

                        <div class="mg">
                            <router-link tag="div"
                                         :to="{path:'/leisure/jwcasinoangel', query: {t: new Date().getTime()}}">
                                <img src="../../assets/images/mobile/wine/menu/jwangle.jpg" alt="">
                            </router-link>
                        </div>
                        <div class="mg">
                            <router-link tag="div"
                                         :to="{path:'/leisure/jwcasinoost', query: {t: new Date().getTime()}}">
                                <img src="../../assets/images/mobile/wine/menu/jwoct.jpg" alt="">
                            </router-link>
                        </div>
                        <div class="mg">
                            <router-link tag="div"
                                         :to="{path:'/leisure/jwcasinosupermario', query: {t: new Date().getTime()}}">
                                <img src="../../assets/images/mobile/wine/menu/jwmario.jpg" alt="">
                            </router-link>
                        </div>
<!--                        <div class="mg">-->
<!--                            <router-link tag="div" to="">-->
<!--                                <img src="../../assets/images/mobile/wine/menu/comming.jpg" alt="">-->
<!--                            </router-link>-->
<!--                        </div>-->

                        <div class="mg">
                            <router-link tag="div"
                                         :to="{path:'/leisure/speedgamepdali', query: {t: new Date().getTime()}}">
                                <img src="../../assets/images/mobile/wine/menu/pigsdl.jpg" alt="">
                            </router-link>
                        </div>
                        <div class="mg">
                            <router-link tag="div"
                                         :to="{path:'/leisure/speedgamelm', query: {t: new Date().getTime()}}">
                                <img src="../../assets/images/mobile/wine/menu/rionmuji.jpg" alt="">
                            </router-link>
                        </div>

                        <div class="mg">
                            <router-link tag="div"
                                         :to="{path:'/leisure/speedgamesplit', query: {t: new Date().getTime()}}">
                                <img src="../../assets/images/mobile/wine/menu/splitbaccarat.jpg" alt="">
                            </router-link>
                        </div>


                        <div class="mg">
                            <router-link tag="div"
                                         :to="{path:'/tgame365/tgame365highlow', query: {t: new Date().getTime()}}">
                                <img src="../../assets/images/mobile/wine/menu/tokenhilow.jpg" alt="">
                            </router-link>
                        </div>
                        <div class="mg">
                            <router-link tag="div"
                                         :to="{path:'/tgame365/tgame365ruollet', query: {t: new Date().getTime()}}">
                                <img src="../../assets/images/mobile/wine/menu/tokenrullet.jpg" alt="">
                            </router-link>
                        </div>
                        <div class="mg">
                            <router-link tag="div"
                                         :to="{path:'/tgame365/tgame365baccarat', query: {t: new Date().getTime()}}">
                                <img src="../../assets/images/mobile/wine/menu/tokenbaccarat.jpg" alt="">
                            </router-link>
                        </div>
                      <div class="mg">
                        <router-link tag="div"
                                     :to="{path:'/leisure/royalsutda', query: {t: new Date().getTime()}}">
                          <img src="../../assets/images/mobile/wine/menu/royalsutda.jpg" alt="">
                        </router-link>
                      </div>

<!--                      <div class="mg">-->
<!--                        <router-link tag="div" to="">-->
<!--                          <img src="../../assets/images/mobile/wine/menu/comming.jpg" alt="">-->
<!--                        </router-link>-->
<!--                      </div>-->

                    </div>


                </div>


            </div>

            <div class="noticeandresult">
                <div class="nt">
                    <div class="nt_title">
                        공지사항 <router-link tag="span" to="/notice" style="float: right;font-size: 12px;color: #c1c1c1;cursor: pointer">+more</router-link>
                    </div>
                    <router-link tag="div" :to="{path: '/notice', query: {id:item.id,t: new Date().getTime()}}"
                                 v-for="item in noticeList"
                                 class="nt_cont">
                        <!--                            <span> <img src="../../assets/images/icon/common/notice.png"></span> {{item.title}}-->
                        <span style="color: #fbce5b"><i class="fa fa-warning"></i></span> <font v-html="item.title"></font>
                    </router-link>
                </div>
                <div class="nt">
                    <div class="nt_title">
                        이벤트 <router-link tag="span" to="/eventlist" style="float: right;font-size: 12px;color: #c1c1c1;cursor: pointer">+more</router-link>
                    </div>
                    <router-link tag="div" :to="{path: '/eventlist', query: {id:item.id,t: new Date().getTime()}}"
                                 v-for="item in eventList"
                                 class="nt_cont">
                        <!--                            <span> <img src="../../assets/images/icon/common/event.png"></span> {{item.title}}-->
                        <span style="color: #fbce5b"><i class="fa fa-gift"></i></span> <font v-html="item.title"></font>
                    </router-link>
                </div>
            </div>


            <div class="sub_mobile">
                <right-bar-banner-comp></right-bar-banner-comp>
            </div>


        </div>
        <!--         right 메뉴 -->
        <right-bar-comp>
            <!--내정보-->
            <user-info-comp slot="userinfo"></user-info-comp>
            <right-buttons-comp slot="btns"></right-buttons-comp>
            <!--배팅카트-->
            <sports-bet-cart-comp slot="betcart" :display-position="displayPosition"></sports-bet-cart-comp>
            <right-bar-banner-comp slot="banner"></right-bar-banner-comp>
        </right-bar-comp>

        <foot-comp></foot-comp>

    </div>


</template>

<script>
    import RightBarComp from "../../components/RightBarComp";
    import TopbarComp from "../../components/TopbarComp";
    import {swiper, swiperSlide} from "vue-awesome-swiper"
    import "swiper/dist/css/swiper.css";
    import FootComp from "../../components/FootComp";
    import SportsLeftBarComp from "../../components/sports/SportsLeftBarComp";
    import LeftBarComp from "../../components/LeftBarComp";
    import sportsConst from "../../common/sportsConst";
    import inplayConst from "../../common/inplayConst";
    import RightBarBannerComp from "../../components/RightBarBannerComp";
    import SportsBetCartComp from "../../components/sports/SportsBetCartComp";
    import {WEBSOCKET_URL} from "../../common/const";
    import {coffepay, getEvent, getNoticeList, owlevent, payback, run369} from "../../network/userRequest";
    import {postionMixin} from "../../common/mixin";
    import MobileMenuItemsComp from "../../components/MobileMenuItemsComp";
    import UserInfoComp from "../../components/UserInfoComp";
    import RightButtonsComp from "../../components/RightButtonsComp";
    import SportsMainShowComp from "../../components/sports/SportsMainShowComp";
    import {getRecentlyGames} from "../../network/sportsRequest";
    import {RECEIVE_HIDE_LOADING, RECEIVE_SHOW_LOADING} from "../../store/mutation-types";
    import EventButtonsComp from "../../components/EventButtonsComp";

    export default {
        name: "Main",
        mixins: [postionMixin],
        components: {
            EventButtonsComp,
            SportsMainShowComp,
            RightButtonsComp,
            UserInfoComp,
            MobileMenuItemsComp,
            SportsBetCartComp,
            RightBarBannerComp,
            LeftBarComp, SportsLeftBarComp, FootComp, TopbarComp, RightBarComp, swiper, swiperSlide
        },
        data() {
            return {
                noticeList: [],
                eventList: [],
                noticePageSize: 8,
                inplayList: [],
                wsData: null,
                websocket: null,
                path: WEBSOCKET_URL,
                position: "메인",
                sportsConst,
                inplayConst,
                displayPosition: this.$store.state.displayPostion,
                soccer: [],
                base: [],
                basket: [],
                hokey: [],
                esports: [],
                vol: [],
                swiperOption: {
                    pagination: {el: '.swiper-pagination'},
                    autoplay: {},
                    disableOnInteraction: false,
                    delay: 2000,
                },
            }
        },
        methods: {
            payback() {
                this.$store.commit(RECEIVE_SHOW_LOADING);
                payback().then(res => {
                    if (res.data.success) {
                        this.$store.dispatch('actionUserInfo')
                        this.$swal({
                            title: '페이백 지급이 완료되였습니다',
                            type: 'success',
                            showCancelButton: false,
                            showConfirmButton: true
                        })
                    } else {
                        this.$swal({
                            title: res.data.msg,
                            type: 'error',
                            showCancelButton: false,
                            showConfirmButton: true
                        })
                    }
                    this.$store.commit(RECEIVE_HIDE_LOADING);
                })
            },
            coffepay() {
                this.$store.commit(RECEIVE_SHOW_LOADING);
                coffepay().then(res => {
                    if (res.data.success) {
                        this.$store.dispatch('actionUserInfo')
                        this.$swal({
                            title: '커피값&식사비 ' + res.data.msg + 'P 지급 완료\r\n\t\r\n\t' +
                                '커피값&식사비는 미니게임만 이용가능합니다 \n' +
                                '롤링300% 최소5회 배팅',
                            type: 'success',
                            showCancelButton: false,
                            showConfirmButton: true
                        })
                    } else {
                        this.$swal({
                            title: res.data.msg,
                            type: 'error',
                            showCancelButton: false,
                            showConfirmButton: true
                        })
                    }
                    this.$store.commit(RECEIVE_HIDE_LOADING);
                })
            },
            owlevent() {
                this.$store.commit(RECEIVE_SHOW_LOADING);
                owlevent().then(res => {
                    if (res.data.success) {
                        this.$store.dispatch('actionUserInfo')
                        this.$swal({
                            title: res.data.msg + 'P 지급 완료\r\n\t\r\n\t' +
                                '올빼미 이벤트란 규정 필수 숙지 후 이용바랍니다.\n',
                            type: 'success',
                            showCancelButton: false,
                            showConfirmButton: true
                        })
                    } else {
                        this.$swal({
                            title: res.data.msg,
                            type: 'error',
                            showCancelButton: false,
                            showConfirmButton: true
                        })
                    }
                    this.$store.commit(RECEIVE_HIDE_LOADING);
                })
            },
            run369() {
                this.$store.commit(RECEIVE_SHOW_LOADING);
                run369().then(res => {
                    if (res.data.success) {
                        this.$store.dispatch('actionUserInfo')
                        this.$swal({
                            title: res.data.msg + 'P 지급 완료\r\n\t\r\n\t' +
                                '[중요]돌발이벤트 규정 숙지 후 이용바랍니다.\n',
                            type: 'success',
                            showCancelButton: false,
                            showConfirmButton: true
                        })
                    } else {
                        this.$swal({
                            title: res.data.msg,
                            type: 'error',
                            showCancelButton: false,
                            showConfirmButton: true
                        })
                    }
                    this.$store.commit(RECEIVE_HIDE_LOADING);
                })
            },

        },
        created() {
            getNoticeList(4).then(res => {
                this.noticeList = res.data.data
            })
            getEvent(1, 4).then(res => {
                this.eventList = res.data.data
            })
            getRecentlyGames().then(res => {
                if (res.data.success) {
                    let recentlryGames = res.data.data
                    this.soccer = recentlryGames.soccer
                    this.basket = recentlryGames.bascket
                    this.base = recentlryGames.base
                    this.vol = recentlryGames.vol
                    this.hokey = recentlryGames.hockey
                    this.esports = recentlryGames.esports
                }
            })
        }
    }
</script>
<style scoped>
    .main .main_img{
        display: flex;
        flex-direction: column;
    }
    .main_img .main-buttons{
        display: none!important;
    }


    .main .main-minigame .mmg01 {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: nowrap;
    }

    .main .main-minigame .mmg01 .mg {
        width: 11%;
        margin: 2px 0;
    }

    .main .main-minigame .mmg01 .mg img {
        width: 100%;
        cursor: pointer;
    }

    .main .main-minigame .mmg01 .mg2 {
        width: 19%;
        margin: 2px 0;
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }

    .main .main-minigame .mmg01 .mg2 img {
        width: 98%;
        cursor: pointer;
    }

    .main .main-sub-links {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: nowrap;
    }

    .main .main-sub-links div {
        width: 33.33%;
        cursor: pointer;
    }

    .main .main-sub-links div img {
        width: 100%;
    }

    .m2 {
        display: none;
        width: 100%;
    }

    .m2 .main-minigame-mobile {
        width: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    .m2 .main-minigame-mobile .mg {
        width: 24.5%;
        margin: 2px 0;
    }

    .m2 .main-minigame-mobile .mg img {
        width: 100%;
        height: 100px;
        cursor: pointer;
    }

    .main .main-sub-links-mobile {
        display: none;
    }

    .main .main-sub-links-mobile div {
        width: 99%;
        margin: 5px 0;
    }

    .main .main-sub-links-mobile div img {
        width: 100%;
        border: 1px solid #ffa500;
    }

    @media screen and (max-width: 1024px) {
        .main_img .main-buttons{
            display: flex!important;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            text-align: center;
            margin-top: 5px;
        }
        .main_img .main-buttons button{
            width: 19.5%;
            height: 42px;
            background:rgba(54,54,54,.8);
            margin-bottom: 1px;
        }

        .m2 {
            display: block;
            width: 100%;
        }

        .m1 {
            display: none !important;
        }

        .main .main-sub-links {
            display: none !important;
        }

        .main .main-sub-links-mobile {
            display: flex;
            justify-content: center;
            align-items: flex-start;
            flex-wrap: wrap;
        }


    }


    .main .sub_img {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        margin-top: 16px;
    }

    .main .sub_img .sub {
        width: 19.5%;
        cursor: pointer;
    }

    .main .sub_img img {
        width: 100%;
    }

    .sub_mobile {
        display: none;
        width: 100%;
    }

    .main .pc {

    }

    .main .mobile {
        display: none;
    }

    .noticeandresult {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        margin-top: 10px;
        flex-wrap: wrap;
        color: #bcbcbc;
    }

    .noticeandresult .nt {
        width: 49.6%;
        text-align: left;
        padding: 0 10px;
        background-color: #161616;
    }

    .noticeandresult .nt .nt_title {
        width: 100%;
        height: 42px;
        line-height: 42px;
        border-bottom: 2px solid #8f8f8f;
        padding-left: 10px;
        font-size: 18px;
        font-weight: bold;
        color: #fff9ff;
        margin-bottom: 10px
    }

    .noticeandresult .nt .nt_cont {
        width: 100%;
        height: 40px;
        line-height: 40px;
        padding-left: 10px;
        font-size: 14px;;
        font-weight: bold;
        cursor: pointer
    }
    .noticeandresult .nt .nt_cont img{
        height: 20px;
    }

    .noticeandresult .res {
        width: 60%;
        text-align: right;
        padding: 0 10px;
    }

    .noticeandresult .res div {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    @media screen and (max-width: 1024px) {
        .main .sub_img {
            margin-top: 5px!important;
        }
        .main .sub_img .sub {
            display: none !important;
            width: 100% !important;
            padding: 16px 16px;
        }

        .sub_mobile {
            display: block !important;
            margin-top: 10px;
        }

        .main .pc {
            display: none !important;
        }

        .main .mobile {
            display: block !important;
        }

        .noticeandresult .nt {
            flex-wrap: wrap;
            width: 100% !important;
        }
    }


</style>
